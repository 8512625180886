const mainTheme = {
  color: {
    primary: '#d70926',
    secondary: '#D8D8D8',
    tertiary: '#EB6209',
    light: '#FFFFFF',
    dark: '#101010',
  },

  font: {
    family: {
      main: 'Montserrat',
      secondary: 'Iceberg',
    },

    size: {
      8: '8px',
      10: '10px',
      12: '12px',
      14: '14px',
      15: '15px',
      16: '16px',
      18: '18px',
      20: '20px',
      24: '24px',
      28: '28px',
      32: '32px',
      36: '36px',
      45: '45px',
      70: '70px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 700,
      extraBold: 800,
    },
  },
}

export default mainTheme

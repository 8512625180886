/* eslint-disable import/no-unresolved */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'
import Phone from 'img/call.inline.svg'
import Email from 'img/email.inline.svg'
import Linkedin from 'img/linkedin.inline.svg'

// Components
import CustomLink from 'components/shared/CustomLink'
import Submenu from 'components/elements/Header/Submenu'

// Third Party
import { motion } from 'framer-motion'

const StyledHeader = styled.nav`
  background-color: ${(props) => props.theme.color.primary};
  position: absolute;
  left: 0;
  top: 40px;
  right: 0;
  height: 93px;
  z-index: 10;
`

const WhiteHeader = styled.div`
  background-color: ${(props) => props.theme.color.light};
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 40px;
  z-index: 10;
  font-size: 14px;
  font-weight: ${(props) => props.theme.font.weight.bold};

  a {
    transition: all 0.3s ease;
    &:hover {
      color: ${(props) => props.theme.color.primary};
      text-decoration: underline;
    }
  }

  & svg {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(CustomLink)`
  & > svg {
    height: 37px;
    width: 133px;
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 4px;
  width: 60%;
  margin: 5px auto;
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({ setIsScrollable }) => {
  // eslint-disable-next-line
  const fields = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
              target
            }
            submenu {
              link {
                title
                url
              }
            }
          }
          info {
            email {
              title
              url
            }
            phone {
              title
              url
            }
            linkedin {
              title
              url
            }
          }
        }
      }

      allWpVacature {
        totalCount
      }
    }
  `)

  return (
    <>
      <WhiteHeader>
        <div className="container h-100">
          <div className="h-100 d-flex justify-content-end align-items-center">
            <a
              href={fields.wpComponent?.header?.info?.phone?.url}
              className="mr-4"
            >
              <Phone />
              {fields.wpComponent?.header?.info?.phone?.title}
            </a>
            <a href={fields.wpComponent?.header?.info?.email?.url}>
              <Email />
              {fields.wpComponent?.header?.info?.email?.title}
            </a>
          </div>
        </div>
      </WhiteHeader>
      <StyledHeader>
        <Container className="container">
          <Brand to="/">
            <Logo />
          </Brand>

          <MenuMobile fields={fields} setIsScrollable={setIsScrollable} />
          <MenuDesktop fields={fields} />
        </Container>
      </StyledHeader>
    </>
  )
}

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  padding: 30px 0 0;

  & > ul {
    padding: 50px 0 0;
    list-style-type: none;
    text-align: center;
    width: 70%;
    margin: 0 auto;

    & li {
      padding: 0;

      & a {
        font-size: 40px;
        line-height: 40px;
        display: block;
        padding: 20px 0;
        text-align: left;

        &[aria-current] {
          color: ${(props) => props.theme.color.primary};
        }

        &:before {
          content: none !important;
        }

        &:after {
          content: none !important;
        }

        &:hover {
          color: ${(props) => props.theme.color.secondary};
        }
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 4px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

interface MenuMobileProps {
  fields: any
  setIsScrollable: Function
}

const MenuMobile: React.FC<MenuMobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

const StyledMenuDesktop = styled.ul`
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  margin-bottom: 0;
`

const LinkedinWrapper = styled(CustomLink)`
  & svg {
    width: 30px;
    height: 30px;
    fill: ${(props) => props.theme.color.light};

    &:hover {
      fill: ${(props) => props.theme.color.dark};
    }
  }
`

interface MenuDesktopProps {
  fields: any
}

const MenuDesktop: React.FC<MenuDesktopProps> = ({ fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu fields={fields} />
    <LinkedinWrapper
      to={fields.wpComponent.header.info.linkedin.url}
      className="ml-4 pb-1"
    >
      <Linkedin />
    </LinkedinWrapper>
  </StyledMenuDesktop>
)

const Item = styled(motion.li)`
  margin-left: 30px;

  &:first-of-type {
    margin-left: 0;
  }

  & > a {
    color: ${(props) => props.theme.color.light};
    font-weight: ${(props) => props.theme.font.weight.regular};
    font-family: ${(props) => props.theme.font.family.secondary};
    text-transform: uppercase;
    transition: all 0.3s ease;

    &[aria-current] {
      position: relative;
      color: ${(props) => props.theme.color.dark};

      &:before {
        content: '';
        position: absolute;
        bottom: -37px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        width: 20px;
        border-top: 20px solid transparent;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 20px solid ${(props) => props.theme.color.dark};
      }

      &:after {
        content: '';
        position: absolute;
        width: 110%;
        height: 5px;
        left: 0;
        background-color: ${(props) => props.theme.color.primary};
        bottom: -5px;
      }
    }

    &:hover {
      color: ${(props) => props.theme.color.dark};
    }
  }

  @media screen and (max-width: 1200px) {
    & > a {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    margin-left: 0;
  }
`

const UnderLine = styled(motion.div)`
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: ${(props) => props.theme.color.dark};

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Counter = styled.div`
  position: absolute;
  top: -20px;
  right: -15px;
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};
  font-family: ${(props) => props.theme.font.family.secondary};
  font-size: 12px;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    background-color: ${(props) => props.theme.color.light};
    color: ${(props) => props.theme.color.primary};
    width: 30px;
    height: 30px;
    font-size: 14px;
    border-radius: 15px;
    top: 0;
  }
`

interface MenuProps {
  fields: any
}

const Menu: React.FC<MenuProps> = ({ fields }) => (
  <>
    {fields.wpComponent?.header.menuHeader.map(
      ({ link: { url, title, target }, submenu }: any, index: number) => {
        const [isOpen, setOpen] = useState(false)

        return (
          <Item
            initial="init"
            whileHover="hover"
            onHoverStart={() => setOpen(true)}
            onHoverEnd={() => setOpen(false)}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="position-relative d-flex"
          >
            {submenu && <Counter>{fields.allWpVacature.totalCount}</Counter>}
            <CustomLink target={target} to={url}>
              {title}
            </CustomLink>
            <UnderLine
              variants={{
                hover: { width: '100%' },
                init: { width: '0%' },
              }}
            />
            {submenu && <Submenu isOpen={isOpen} fields={submenu} />}
          </Item>
        )
      }
    )}
  </>
)

export default Header

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import Pin from 'img/pin.inline.svg'
import Phone from 'img/call.inline.svg'
import Email from 'img/email.inline.svg'

// Third Party
import styled from 'styled-components'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.secondary};

  & h2 {
    font-size: 20px;
    font-family: ${(props) => props.theme.font.family.secondary};
    text-transform: uppercase;
    padding-bottom: 40px;
  }

  & li,
  a {
    list-style: none;
    font-size: 14px;
    padding: 8px 0 8px 0;

    &:hover {
      text-decoration: underline;
    }
  }

  & p {
    font-size: 14px;
  }

  & ul {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 100%;
      width: 2px;
      background-color: ${(props) => props.theme.color.primary};
    }
  }

  & svg {
    height: 20px;
    width: 20px;
    fill: #ffffff;
    margin-right: 1rem;
  }

  @media screen and (max-width: 991px) {
    & h2 {
      padding-bottom: 10px;
      padding-top: 20px;
    }
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
            }
          }
          vacaturesFooter {
            link {
              title
              url
            }
          }
          contactFooter {
            address
            zipcode
            phone {
              title
              url
            }
            email {
              title
              url
            }
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4">
            <h2>Menu</h2>
            <ul>
              {fields?.footer?.menuFooter?.map(
                ({ link: { url, title } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-lg-4">
            <h2>Vacatures</h2>
            <ul>
              {fields?.footer?.vacaturesFooter?.map(
                ({ link: { url, title } }: any, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index}>
                    <Link to={url}>{title}</Link>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="col-lg-4">
            <h2>Contact</h2>
            <div className="d-flex">
              <Pin />
              <div>
                <p className="pb-3 mb-0">
                  {fields?.footer?.contactFooter?.address}
                </p>
                <p className="pb-3 mb-0">
                  {fields?.footer?.contactFooter?.zipcode}
                </p>
              </div>
            </div>
            <div className="pb-3">
              <a href={fields?.footer?.contactFooter?.phone?.url}>
                <Phone />
                {fields?.footer?.contactFooter?.phone?.title}
              </a>
            </div>
            <div className="pb-3">
              <a href={fields?.footer?.contactFooter?.email?.url}>
                <Email />
                {fields?.footer?.contactFooter?.email?.title}
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer

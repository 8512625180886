import React from 'react'

// Components
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

interface SubmenuProps {
  fields: Array<{
    link: {
      title: string
      url: string
    }
  }>
  isOpen: boolean
}

const StyledSubmenu = styled(motion.div)`
  position: absolute;
  overflow: hidden;
  top: 25px;
  width: 210px;
  left: calc(50% - 105px);
  padding-top: 36px;
  text-align: center;

  & > div {
    background-color: ${(props) => props.theme.color.light};
  }

  & a {
    padding: 4px 0;
    font-size: 14px;
    text-transform: uppercase;

    &[aria-current] {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields, isOpen }) => (
  <AnimatePresence>
    {isOpen && (
      <StyledSubmenu
        initial={{ height: '0%' }}
        animate={{ height: 'fit-content' }}
        exit={{ height: '0%' }}
      >
        <div className="p-4">
          {fields.map((field) => (
            <div>
              <CustomLink to={field.link.url}>{field.link.title}</CustomLink>
            </div>
          ))}
        </div>
      </StyledSubmenu>
    )}
  </AnimatePresence>
)

export default Submenu
